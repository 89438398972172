<template>
  <div class="report-wrapper" v-show="show">
    <div class="background"></div>
    <div class="report">
      <div class="title-wrapper">
        <!--        <p class="title">带访预约修改</p>-->
        <p class="title">快速报备</p>
        <el-image class="close" @click="handleClose" src="/static/images/report_title_close.png" fit="cover"></el-image>
      </div>
      <div class="content-wrapper">
        <p class="sub-title">客户信息</p>
        <div class="line one">
          <div class="item left">
            <span class="label">姓名:</span>
            <span class="require">*</span>
            <input class="input" v-model="form.client_name" placeholder="请输入姓名"/>
          </div>
          <div class="item center sex-wrapper">
            <span class="label">性别:</span>
            <span class="require">*</span>
            <div class="sex">
              <div class="man" @click="handleSexChange(1)">
                <el-image v-show="form.client_sex === 1" class="select" src="/static/images/report_select_selected.png"
                          fit="fill"></el-image>
                <el-image v-show="form.client_sex !== 1" class="select" src="/static/images/report_select.png"
                          fit="fill"></el-image>
                <span>男</span>
              </div>
              <div class="male" @click="handleSexChange(2)">
                <el-image v-show="form.client_sex === 2" class="select" src="/static/images/report_select_selected.png"
                          fit="fill"></el-image>
                <el-image v-show="form.client_sex !== 2" class="select" src="/static/images/report_select.png"
                          fit="fill"></el-image>
                <span>女</span>
              </div>
            </div>
          </div>
          <div class="item right phone-num-wrapper">
            <div class="top">
              <div class="all" @click="handlePhoneTypeChange(1)">
                <el-image v-show="form.client_phone_type === 1" class="select"
                          src="/static/images/report_select_selected.png" fit="fill"></el-image>
                <el-image v-show="form.client_phone_type !== 1" class="select" src="/static/images/report_select.png"
                          fit="fill"></el-image>
                <span>完整号码</span>
              </div>
              <div class="noall" @click="handlePhoneTypeChange(2)">
                <el-image v-show="form.client_phone_type === 2" class="select"
                          src="/static/images/report_select_selected.png" fit="fill"></el-image>
                <el-image v-show="form.client_phone_type !== 2" class="select" src="/static/images/report_select.png"
                          fit="fill"></el-image>
                <span>前三后四</span>
              </div>
            </div>
            <div class="bottom">
              <span class="label">联系电话:</span>
              <span class="require">*</span>
              <input class="input" v-show="form.client_phone_type === 1" maxlength="11" placeholder="请输入联系电话"
                     v-model="form.client_phone"/>
              <input class="input prefix" v-show="form.client_phone_type === 2" maxlength="3" placeholder="前三号码"
                     v-model="form.client_phone_prefix"/>
              <span class="separate" v-show="form.client_phone_type === 2">****</span>
              <input class="input suffix" v-show="form.client_phone_type === 2" maxlength="4" placeholder="后四号码"
                     v-model="form.client_phone_suffix"/>
            </div>
          </div>
        </div>

        <div class="line two">
          <div class="item left sex-wrapper">
            <span class="label">性质:</span>
            <span class="require">*</span>
            <div class="sex">
              <div class="man" @click="handleTypeChange(1)">
                <el-image v-show="form.client_type === 1" class="select" src="/static/images/report_select_selected.png"
                          fit="fill"></el-image>
                <el-image v-show="form.client_type !== 1" class="select" src="/static/images/report_select.png"
                          fit="fill"></el-image>
                <span>购买</span>
              </div>
              <div class="male" @click="handleTypeChange(2)">
                <el-image v-show="form.client_type === 2" class="select" src="/static/images/report_select_selected.png"
                          fit="fill"></el-image>
                <el-image v-show="form.client_type !== 2" class="select" src="/static/images/report_select.png"
                          fit="fill"></el-image>
                <span>租赁</span>
              </div>
            </div>
          </div>
          <div class="item center come-time">
            <span class="label">到访时间:</span>
            <span class="require">*</span>
            <el-select class="date" v-model="form.come_time_date" :teleported="false" placeholder="到访日期">
              <el-option
                  v-for="item in come_time_date_list"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
            <el-select v-model="form.come_time_time" :teleported="false" placeholder="到访时间">
              <el-option
                  v-for="item in come_time_time_list"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </div>
          <div class="item right receptionist">
            <span class="label">案场接待:</span>
            <span class="require">*</span>
            <el-select v-model="form.receptionist_id" :teleported="false" placeholder="请选择案场接待">
              <el-option
                  v-for="item in receptionist_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="line border"></div>

        <p class="sub-title">需求信息</p>

        <div class="line three">
          <div class="item left receptionist">
            <span class="label">意向类型:</span>
            <span class="require">*</span>
            <el-select v-model="form.intention_id" :teleported="false" placeholder="请选择意向类型">
              <el-option
                  v-for="item in intention_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="item right demand_area">
            <span class="label">需求面积:</span>
            <span class="require">*</span>
            <div class="area">
              <input class="input" v-model="form.demand_area_min" placeholder="最小面积"/>
              <span>-</span>
              <input class="input" v-model="form.demand_area_max" placeholder="最大面积"/>
              <span>㎡</span>

            </div>
          </div>
        </div>
        <div class="line four">
          <div class="item remark">
            <span class="label">客户备注:</span>
            <span class="require"></span>
            <textarea placeholder="请输入客户更多需求..." v-model="form.remark"></textarea>
          </div>
        </div>
        <div class="line border"></div>

        <p class="sub-title">经纪人信息</p>
        <div class="line five">
          <div class="item left receptionist">
            <span class="label">经纪人公司:</span>
            <span class="require">*</span>
            <el-select v-model="form.broker_company_id" :teleported="false" placeholder="请选择经纪人公司">
              <el-option
                  v-for="item in broker_company_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="item center">
            <span class="label">姓名:</span>
            <span class="require">*</span>
            <input class="input" v-model="form.broker_name" placeholder="请输入姓名"/>
          </div>
          <div class="item right">
            <span class="label">手机号:</span>
            <span class="require">*</span>
            <input class="input" v-model="form.broker_phone" maxlength="11" placeholder="请输入手机号"/>
          </div>
        </div>

        <div class="line six">
          <!--          <button class="cancel" @click="handleClose">取消预约到访</button>-->
          <button class="submit" @click="handleSubmitClick">报备</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FastReport',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    project_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: {
        project: [],
        come_time: '',
        come_time_date: '',
        come_time_time: '',
        receptionist_id: '',
        intention_id: '',
        demand_area_min: '',
        demand_area_max: '',
        client_phone_type: 1,
        client_phone: '',
        client_phone_prefix: '',
        client_phone_suffix: '',
        broker_company_id: '',
        broker_name: '',
        broker_phone: '',
        client_sex: 1,
        client_type: 1,
        client_name: '',
        remark: '',
      },
      come_time_date_list: [],
      come_time_time_list: [],
      receptionist_list: [],
      intention_list: [],
      broker_company_list: [],
    }
  },
  mounted() {
    this.buildComeTimeDateList()
    this.buildComeTimeTimeList()
    this.fetchReceptionist()
    this.fetchIntention()
    this.fetchBrokerCompany()
  },
  methods: {
    buildComeTimeDateList() {
      let currentDate = (new Date())

      for (let i = 0; i !== 7; i++) {
        this.come_time_date_list.push(`${(currentDate.getMonth() + 1).toString().padStart(2, '0')}月${currentDate.getDate().toString().padStart(2, '0')}日`)
        currentDate.setDate(currentDate.getDate() + 1)
      }
    },
    buildComeTimeTimeList() {
      for (let h = 8; h !== 23; h++) {
        this.come_time_time_list.push(`${h.toString().padStart(2, '0')}:00`)
        this.come_time_time_list.push(`${h.toString().padStart(2, '0')}:30`)
      }
    },
    async fetchReceptionist() {
      let res = await this.http({
        url: '/api/report/receptionist',
        method: 'GET',
        params: {
          project_id: this.project_id
        }
      })
      this.receptionist_list = res.data
    },
    async fetchIntention() {
      let res = await this.http({
        url: '/api/report/intention',
        method: 'GET'
      })
      this.intention_list = res.data
    },
    async fetchBrokerCompany() {
      let res = await this.http({
        url: '/api/report/brokerCompany',
        method: 'GET'
      })
      this.broker_company_list = res.data
    },
    handleClose() {
      this.$emit('update:show', false)
    },
    handleSexChange(index) {
      this.form.client_sex = index
    },
    handlePhoneTypeChange(index) {
      this.form.client_phone_type = index
    },
    handleTypeChange(index) {
      this.form.client_type = index
    },
    async handleSubmitClick() {
      this.form.project = [{
        project_id: this.project_id,
        come_time: '',
        receptionist_id: '',
      }]

      if (this.form.client_name === '') {
        this.$message({
          type: 'warning',
          message: '请输入姓名'
        })
        return
      }
      if (this.form.client_phone_type === 1) {
        if (this.form.client_phone === '' || this.form.client_phone.length !== 11) {
          this.$message({
            type: 'warning',
            message: '请输入正确的联系电话'
          })
          return
        }
      } else {
        if (
            this.form.client_phone_prefix === '' ||
            this.form.client_phone_prefix.length !== 3 ||
            this.form.client_phone_suffix === '' ||
            this.form.client_phone_suffix.length !== 4
        ) {
          this.$message({
            type: 'warning',
            message: '请输入正确的联系电话'
          })
          return
        }
      }

      if (this.form.come_time_date === '') {
        this.$message({
          type: 'warning',
          message: '请选择到访日期'
        })
        return
      }

      if (this.form.come_time_time === '') {
        this.$message({
          type: 'warning',
          message: '请选择到访时间'
        })
        return
      }

      this.form.project[0].come_time = `${this.form.come_time_date} ${this.form.come_time_time}`

      if (this.form.receptionist_id === '') {
        this.$message({
          type: 'warning',
          message: '请选择案场接待'
        })
        return
      }

      this.form.project[0].receptionist_id = this.form.receptionist_id

      if (this.form.intention_id === '') {
        this.$message({
          type: 'warning',
          message: '请选择意向类型'
        })
        return
      }

      if (this.form.demand_area_min === '' || this.form.demand_area_max === '') {
        this.$message({
          type: 'warning',
          message: '请输入需求面积'
        })
        return
      }

      if (this.form.broker_company_id === '') {
        this.$message({
          type: 'warning',
          message: '请选择经纪人公司'
        })
        return
      }

      if (this.form.broker_name === '') {
        this.$message({
          type: 'warning',
          message: '请输入经纪人姓名'
        })
        return
      }

      if (this.form.broker_phone === '' || this.form.broker_phone.length !== 11) {
        this.$message({
          type: 'warning',
          message: '请输入正确的经纪人手机号'
        })
        return
      }

      // 提交信息

      let res = await this.http({
        url: '/api/report/register',
        method: 'POST',
        data: this.form
      })

      this.$emit('afterSubmit', res)
    }
  },
  watch: {
    project_id() {
      this.fetchReceptionist()
    }
  }
}
</script>

<style scoped lang="scss">
@import "FastReport.scss";
</style>
