<template>
  <div class="report-result-wrapper" v-show="show">
    <div class="background"></div>
    <div class="report-result">
      <div class="title-wrapper">
        <el-image class="close" @click="handleTitleCloseClick" src="/static/images/report_result_title_close.png" fit="cover"></el-image>
        <div class="con">
          <el-image v-if="reportType === 'success'" class="result-icon"
                    src="/static/images/report_result_title_success.png" fit="cover"></el-image>
          <el-image v-if="reportType === 'error'" class="result-icon" src="/static/images/report_result_title_error.png"
                    fit="cover"></el-image>
          <span class="title">{{ reportType === 'success' ? '报备成功' : '报备失败' }}</span>
        </div>
      </div>
      <div class="result-content-wrapper">
        <p v-if="reportType === 'success'" class="msg">客户报备成功，请关注论楼官方微信，及时了解跟进信息。</p>
        <p v-if="reportType === 'error'" class="msg error">经系统判定，报备客户与项目上门客户撞客，<br>请确认客户信息是否填写正确？
        </p>
        <div class="qrcode-wrapper">
          <el-image class="qrcode" src="/static/images/mp-qrcode.png" fit="cover"/>
          <div class="right">
            <span>扫描微信二维码</span>
            <span>关注论楼官方微信</span>
            <span>获取最新的新闻资讯</span>
          </div>
        </div>

        <div class="button-wrapper">
          <button v-if="reportType === 'success'" class="back" @click="handleButtonCancelClick">返回首页</button>
          <button v-if="reportType === 'error'" class="back" @click="handleButtonChangeClick">返回修改</button>
          <button class="chat" @click="handleButtonChatClick">在线咨询</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportResult',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    reportType: {
      type: String,
      default: 'success'
    }
  },
  methods: {
    handleTitleCloseClick() {
      this.$emit('update:show', false)
      this.$emit('close', false)
    },
    handleButtonCancelClick(){
      this.$emit('update:show', false)
      this.$emit('close', false)
    },
    handleButtonChangeClick(){
      this.$emit('update:show', false)
    },
    handleButtonChatClick(){
      window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.qq}&site=qq&menu=yes`,'_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import "ReportResult";
</style>
